import { AuthtokenService } from "src/app/_services/authtoken.service";

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-menu-toolbar-component",
  templateUrl: "./menu_toolbar.component.html",
  styleUrls: ["./menu_toolbar.component.css"],
})
export class MenuToolbarComponent implements OnInit {
  authToken: string;
  active: string;

  constructor(
    private router: Router,
    private tokenService: AuthtokenService,
  ) {
    this.tokenService.currentToken.subscribe((token) => {
      if (token) {
        this.authToken = token;
      }
    });
  }

  ngOnInit() {
    this.active = "/" + this.router.url.split("/")[1];
  }

  changeApp(data: any) {
    this.router.navigate([data.link]);
  }
}
