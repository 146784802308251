import { Injectable } from '@angular/core';
import { AppConstants } from '../_constants/app.constants';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
    AWSAuth: any; // to allow spying for tests

    constructor(
        private http: HttpClient
    ) {    }

    signIn(payload: any) {
        return this.http.post(AppConstants.API_HOST_NAME + '/auth/signin',
            JSON.stringify(payload), {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        });
    }

    getApps(token: any) {
        return this.http.get(AppConstants.API_HOST_NAME + '/user-accounts/apps', {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            })
        });
    }

}
