export class BaseQuestion<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    options: any;
    disabled: boolean;
    type: string;
    title: string;
    path: string;
    section: string;
    sectionIndex: number;
    parameterType: string;
    secondaryVariables: any;

    constructor(options: {
        value?: T,
        key?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        options?: any,
        disabled?: boolean
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.options = options.options || [];
        this.disabled = options.disabled ? options.disabled : false;
        this.type = '';
        this.title = '';
    }
}
