<div class="full-width">
    <div class="progress-bar-container">
        <mat-progress-bar mode="indeterminate" *ngIf="loading" value="40"></mat-progress-bar>
    </div>
    <div class="container table-container">
        <mat-form-field class="edit-full-width">
            <input type="text" placeholder="{{question.label}}" matInput [formControl]="myControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onItemSelect($event)">
                <mat-option *ngIf="spinnerLoading" class="is-loading">
                    <mat-spinner diameter="50"></mat-spinner>
                </mat-option>
                <ng-container *ngIf="!spinnerLoading">
                    <mat-option *ngFor="let option of filteredOptions" [value]="option">
                        {{option.name}}
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
        </mat-form-field>

        <div class="row-ctr">
            <div class="selection" *ngFor="let sel of presentSelection">{{sel[1]}}
                <mat-icon class="selection-icon">close</mat-icon>
            </div>
        </div>


    </div>
</div>