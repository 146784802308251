<div class="app-parent-container main-bg">
    <app-main-toolbar-component></app-main-toolbar-component>

    <div class="app-container" [appHeight]="170">

        <div class="container">
            <div class="row">
                <br>
                <div class="row container">
                    <div class="titles">
                        <h6 class="welcome">Hello {{userProfile?.name}}</h6>
                    </div>
                    <span class="my-spacer"></span>

                </div>

                <div class="stats-container">
                    <div class="col-sm stat mat-elevation-z2 margin-r10">
                        <p class="card-title  bordered-bottom">Organizations</p>
                        <span class="large-value">{{stats[0] ? stats[0][0] : 0 }}</span>
                        <div class="row vertical-flex">
                            <span class="small-value">{{stats[0] ? stats[0][1] : 0 }}</span>
                            <span class="small-header">Total</span>
                        </div>
                    </div>

                    <div class="col-sm stat mat-elevation-z2 margin-r10">
                        <p class="card-title  bordered-bottom">Businesses</p>
                        <span class="large-value">{{stats[1] ? stats[1][0] : 0 }} </span>
                        <div class="row vertical-flex">
                            <span class="small-value">{{stats[1] ? stats[1][1] : 0 }}</span>
                            <span class="small-header">Total</span>
                        </div>
                    </div>

                    <div class="col-sm stat mat-elevation-z2 margin-r10">
                        <p class="card-title  bordered-bottom">Users</p>
                        <span class="large-value">{{stats[2] ? stats[2][0] : 0 }}</span>
                        <div class="row vertical-flex">
                            <span class="small-value">{{stats[2] ? stats[2][1] : 0 }}</span>
                            <span class="small-header">Total</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>