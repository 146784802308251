import { DynamicFormSelectComponent } from './dynamic-form-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        CommonModule
    ],
    declarations: [
        DynamicFormSelectComponent
    ],
    exports: [
        DynamicFormSelectComponent
    ]
})
export class DynamicFormSelectModule {

}
