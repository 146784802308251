import { BaseFormQuestion } from './../../_models/questions/baseform-question';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
    selector: 'app-dynamic-baseform-question-component',
    templateUrl: './dynamic-baseform-question.component.html',
    styleUrls: [
        './dynamic-baseform-question.component.css'
    ]
}) export class DynamicBaseFormQuestionComponent implements OnInit {
    @Input() question: BaseFormQuestion;
    @Output() selectionMade = new EventEmitter<any>();

    myForm: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit() {
        this.myForm = this.fb.group({
            price: ['', Validators.required],
            quantity: ['', [Validators.required]],
            available: ['', [Validators.required]],
            comments: ['', [Validators.required]]
        });
        this.myForm.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => {
                this.selectionMade.emit({
                    key: this.question.key, data: [
                        this.question.key,
                        this.myForm.get('price').value,
                        this.myForm.get('quantity').value,
                        this.myForm.get('available').value,
                        this.myForm.get('comments').value,
                    ]
                });
            });
    }
}
