import { ReactiveFormsModule } from '@angular/forms';
import { DynamicLabelComponent } from './dynamic-label.component';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'src/app/_material/my_material.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,

        MyMaterialModule,
    ],
    declarations: [
        DynamicLabelComponent,
    ],
    exports: [
        DynamicLabelComponent,
    ],
}) export class DynamicLabelModule {

}
