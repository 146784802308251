import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionControlService } from './../dynamic-form/question-control.service';
import { DynamicFormQuestionComponent } from './question.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,

        MatFormFieldModule,
        MatCheckboxModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        MatListModule,
        MatDatepickerModule,
        MatSlideToggleModule,

        CommonModule
    ],
    declarations: [
        DynamicFormQuestionComponent
    ],
    providers: [
        QuestionControlService
    ],
    exports: [
        DynamicFormQuestionComponent
    ]
})
export class DynamicFormQuestionModule {

}
