<div>
    <div class="heading">
        <mat-icon class="m-icon">account_circle</mat-icon>
        <div class="">
            <p class="name">{{data?.name}}</p>
            <p class="title">{{data?.title}}</p>
        </div>
    </div>
    <div class="content">
        <span class="left">Role</span>
        <span class="right">{{data?.role?.name}}</span>
    </div>

    <div class="content">
        <span class="left">Organization</span>
        <span class="right">{{data?.organization?.name}}</span>
    </div>
</div>