<div class="app-component-container main-bg" [appHeight]="0">
    <div class="login-container ">
        <div class="logo-container">
            <img class="login-img" src="./assets/images/actisales_white.png" />
            <h4>Actisales | Admin</h4>
        </div>
        <div class="signin-container">
            <section>
                <mat-progress-bar *ngIf="loading" class="loading-bar" color="accent" mode="indeterminate">
                </mat-progress-bar>
                <div class="login-title">
                    <h4>Sign in</h4>
                </div>

                <form (ngSubmit)="login()" [formGroup]="loginForm" class="login-form">
                    <mat-form-field class="login-full-width" style="text-align: center">
                        <input matInput placeholder="Username" type="tel" formControlName="email">
                    </mat-form-field>

                    <mat-form-field class="login-full-width" style="text-align: center">
                        <input matInput placeholder="Password" type="password" formControlName="password">
                    </mat-form-field>

                    <mat-form-field class="login-full-width" style="text-align: center" *ngIf="newPasswordRequired">
                        <input matInput placeholder="New password" type="password" formControlName="password1">
                    </mat-form-field>

                    <mat-form-field class="login-full-width" style="text-align: center" *ngIf="newPasswordRequired">
                        <input matInput placeholder="Confirm new password" type="password" formControlName="password2">
                    </mat-form-field>

                    <button class="login-button blue" type="submit" mat-raised-button [disabled]="loading">Login</button>
                </form>
            </section>
        </div>
    </div>
</div>