import { QuestionService } from "../../_services/question.service";
import {
  Component,
  ChangeDetectorRef,
  AfterViewChecked,
  OnInit,
  Optional,
} from "@angular/core";
import { UtilFunctions } from "src/app/_constants/utils.functions";
import { Location } from "@angular/common";
import { DataSharingService } from "src/app/_services/data.sharing.service";
import { QuestionnaireService } from "src/app/_services/questionnaire.service";
import { AuthtokenService } from "src/app/_services/authtoken.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, Subscription, fromEvent } from "rxjs";

@Component({
  selector: "app-questionnaire-modal-component",
  templateUrl: "./generic.questionnaire.component.html",
  styleUrls: ["./generic.questionnaire.component.css"],
})
export class GenericQuestionnaireComponent implements AfterViewChecked, OnInit {
  questions: any;
  meta: any;
  authToken: string;
  loading: boolean;
  defaults: any = {};
  isModal: boolean;

  contentHeight: any;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(
    private cdRef: ChangeDetectorRef,
    private _location: Location,
    @Optional() public dialogRef: MatDialogRef<GenericQuestionnaireComponent>,
    private tokenService: AuthtokenService,
    private snackbar: MatSnackBar,
    private questionService: QuestionService,
    private questionnaireService: QuestionnaireService,
    private dataSharingService: DataSharingService
  ) {
    this.loading = false;
    this.isModal = true;
    this.tokenService.currentToken.subscribe((token) => {
      if (token) {
        this.authToken = token;
        this.loadQuestions();
      }
    });
  }

  ngAfterViewChecked() {
    // explicit change detection to avoid "expression-has-changed-after-it-was-checked-error"
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.resizeObservable$ = fromEvent(window, "resize");
    this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
      this.contentHeight = window.innerHeight - 90 + "px";
    });
  }

  loadQuestions() {
    this.loading = true;
    this.dataSharingService.currentObject.subscribe((res: any) => {
      if (!res.modal) {
        this.isModal = false;
        this.contentHeight = window.innerHeight - 90 + "px";
      }
      if (UtilFunctions.isEmpty(res)) {
        this._location.back();
      } else {
        this.meta = res;
        this.getAnyDefaults();
        switch (this.meta.type) {
          case "user-role":
            this.questionService
              .getRoleQuestions(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;

          case "organization":
            this.questionService
              .getOrganizationQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "billing-transaction":
            this.questionService
              .getBillingTransactionQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "billing-account":
            this.questionService
              .getBillingAccountQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "billing-invoice":
            this.questionService
              .getBillingInvoiceQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "billing-receipt":
            this.questionService
              .getBillingReceiptQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "billing-invoice-payment":
            this.questionService
              .getBillingInvoicePaymentQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "billing-user":
            this.questionService
              .getBillingUserFields(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          case "app-service":
            this.questionService
              .getAppServiceQns(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
            case "dashboard-filters":
            this.questionService
              .getDashboardFilters(this.meta)
              .subscribe((resp) => {
                this.questions =
                  this.meta.action === "edit"
                    ? UtilFunctions.patchFormValues(resp, this.meta)
                    : resp;
              });
            break;
          default:
            break;
        }
      }
      this.loading = false;
    });
  }

  getAnyDefaults() {
    if (this.meta.hasDefaults) {
      this.dataSharingService.dataDefault.subscribe((resp) => {
        this.defaults = resp;
      });
    }
  }

  formSubmitted(formData: any) {
    this.loading = true;
    const data: any = formData;
    if (this.meta.hasDefaults) {
      if (this.defaults) {
        for (const key in this.defaults) {
          if (this.defaults.hasOwnProperty(key)) {
            data[key] = this.defaults[key];
          }
        }
      }
    }

    if (this.meta.noSubmit) {
      formData.meta = this.meta;
      this.dialogRef.close(formData);
    } else {
      this.questionnaireService
        .postFormData(this.authToken, formData, this.meta)
        .subscribe(
          (res) => {
            this.snackbar.open("Done! ", "SUCCESS", { duration: 2000 });
            setTimeout(() => {
              if (this.meta.modal) {
                this.dialogRef.close();
              } else {
                this._location.back();
              }
            }, 2000);
          },
          (err: any) => {
            this.snackbar.open("request failed: " + err.message, "ERROR", {
              duration: 2000,
            });
          },
          () => {
            this.loading = false;
          }
        );
    }
  }

  /**
   * Navigate back.. duh!!
   */
  navigateBack() {
    this._location.back();
  }
}
