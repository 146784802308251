import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { BaseQuestion } from 'src/app/_models/questions/base-question';


/**
 * @title Table with selection
 */
@Component({
  selector: 'app-dynamic-form-select',
  styleUrls: ['dynamic-form-select.component.css'],
  templateUrl: 'dynamic-form-select.component.html',
})
export class DynamicFormSelectComponent implements OnInit, OnChanges {
  @Input() question: BaseQuestion<any>;
  @Output() selectionMade = new EventEmitter<any>();

  selectKey: string;
  options: any[] = [];
  selection: any[] = [];
  selectedLeftRow: any;
  selectedRightRow: any;
  flag = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question.firstChange && !this.flag) {
      this.question = changes.question.currentValue;
      this.updateView();
      console.log(this.question);
      this.flag = true;
    }
  }

  updateView() {
    this.selectKey = this.question.key;
    const varOptions: any[] = this.question.options;
    const varPresent: any[] = [];
    try {
      this.question.value.forEach((element, index) => {
        varOptions.forEach(el => {
          if (el.id === element.id) {
            this.selection.push(el);
            varPresent.push(el);
          }
        });
      });

      varPresent.forEach(element => {
        varOptions.forEach((item, index) => {
          if (item === element) { varOptions.splice(index, 1); }
        });
      });
    } catch (error) {
      // console.log(error);
    }

    this.options = varOptions;
  }

  ngOnInit() {

  }

  selectLeft(el: any) {
    this.selectedLeftRow = el;
    this.selectedRightRow = undefined;
  }

  selectRight(el: any) {
    this.selectedLeftRow = undefined;
    this.selectedRightRow = el;
  }

  moveRight() {
    if (this.selectedLeftRow) {
      this.options.forEach((item, index) => {
        if (item === this.selectedLeftRow) { this.options.splice(index, 1); }
      });
      this.selection.push(this.selectedLeftRow);
      this.selectedLeftRow = undefined;
      this.updateParent();
    }
  }

  moveLeft() {
    if (this.selectedRightRow) {
      this.selection.forEach((item, index) => {
        if (item === this.selectedRightRow) { this.selection.splice(index, 1); }
      });
      this.options.push(this.selectedRightRow);
      this.selectedRightRow = undefined;
      this.updateParent();
    }
  }

  updateParent() {
    const arr: string[] = [];

    this.selection.forEach(element => {
      arr.push(element.id);
    });

    this.selectionMade.emit({ data: arr, key: this.question.key });
  }
}
