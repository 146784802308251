import { BaseQuestion } from './base-question';


export class DateQuestion extends BaseQuestion<string> {
    controlType = 'date';
    type: any;

    constructor(options: { } = {}) {
        super(options);
        this.type = options['type'] || [];
    }
}

