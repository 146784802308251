import { DynamicFormDualSelectComponent } from './dynamic-form-dual-select.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';

@NgModule({
    imports: [
        MatTableModule,
        MatCheckboxModule,
        CommonModule
    ],
    declarations: [
        DynamicFormDualSelectComponent
    ],
    exports: [
        DynamicFormDualSelectComponent
    ]
})
export class DynamicFormDualSelectModule {

}
