import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseQuestion } from '../../_models/questions/base-question';


@Injectable()
export class QuestionControlService {
    constructor() { }

    toFormGroup(questions: BaseQuestion<any>[]) {
        const group: any = {};

        questions.forEach(question => {
            switch (question.controlType) {
                case 'dual-select':
                case 'tree-select':
                case 'tree-local':
                case 'autocomplete':
                case 'multi_autocomplete':
                case 'select':
                case 'question_label':
                case 'baseform_question':
                    break;
                default:
                    group[question.key] =
                        question.required ? new UntypedFormControl(
                            { value: question.value || '', disabled: question.disabled },
                            Validators.required
                        )
                            : new UntypedFormControl(
                                { value: question.value || '', disabled: question.disabled }
                            );
                    break;
            }
        });
        return new UntypedFormGroup(group);
    }
}
