import { AuthtokenService } from 'src/app/_services/authtoken.service';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/_services/api.service';
import { UserProfileComponent } from 'src/app/_modals/user_profile/user_profile.component';
import { DataSharingService } from 'src/app/_services/data.sharing.service';

@Component({
    selector: 'app-main-toolbar-component',
    templateUrl: './main-toolbar.component.html',
    styleUrls: [
        './main-toolbar.component.css'
    ]
}) export class MainToolbarComponent implements OnInit, OnDestroy {
    menuDialog: any;
    authToken: string;
    mProfile: any;
    url: any;
    profileSubscription$: Subscription;


    constructor(
        private dialog: MatDialog,
        private router: Router,
        private tokenService: AuthtokenService,
        private apiService: ApiService,
        private dataSharingService: DataSharingService
    ) {
        this.tokenService.currentToken.subscribe(token => {
            if (token) {
                this.authToken = token;
            }
        });
        this.profileSubscription$ = this.dataSharingService.currentUserProfile.subscribe(res => {
            this.mProfile = res;
        })
    }

    ngOnInit() {
        this.apiService.genericGET(this.authToken, 'user-accounts/profile').subscribe(resp => {
            this.mProfile = resp;
            this.dataSharingService.changeUserProfile(resp);
            this.url = 'https://storage.googleapis.com/actisales-public/logos/' + resp.organization.id + '.png';
        });

    }

    myProfile() {
        this.dialog.open(UserProfileComponent, {
            data: this.mProfile,
            width: '600px',
            height: '300px'
        });
    }

    viewNotifications() {
        this.router.navigate(['/notifications']);
    }

    logout() {
        sessionStorage.setItem('accessToken', undefined);
        sessionStorage.setItem('tokenType', undefined);
        this.tokenService.setToken(undefined);
        this.router.navigate(['/login']);
    }

    ngOnDestroy() {
        this.profileSubscription$.unsubscribe();
    }
}
