import { AppHeightDirectiveModule } from './../_directives/appHeight/appHeight.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { MyMaterialModule } from '../_material/my_material.module';

@NgModule({
  imports: [
    CommonModule,
    MyMaterialModule,
    ReactiveFormsModule,
    AppHeightDirectiveModule,
  ],
  declarations: [
    LoginComponent,
  ],
  exports: [
    LoginComponent,
  ],
  providers: []
}) export class LoginModule {

}
