import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { from } from 'rxjs';

@Injectable()
export class ActiveSession implements CanActivate {

  constructor(
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const mPromise = new Promise((resolve) => {
      setTimeout(function () {
        resolve('Success!');
      }, 0);
    });

    return from(mPromise.then(() => {
      return true;
    }));

  }
}
