import { DataSharingService } from 'src/app/_services/data.sharing.service';
import { ApiService } from './../_services/api.service';
import { AuthtokenService } from './../_services/authtoken.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-component',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.scss'
    ],
})
export class HomeComponent implements OnInit {
    authToken: string;
    userProfile: any = {};
    stats: any[] = [];
    url: string;

    constructor(
        private dataSharingService: DataSharingService,
        private tokenService: AuthtokenService,
        private apiService: ApiService,
    ) {
        this.tokenService.currentToken.subscribe(token => {
            if (token) {
                this.authToken = token;
            }
        });
    }

    ngOnInit() {
        this.getStats();
        this.dataSharingService.currentUserProfile.subscribe(res => {
            this.userProfile = res;
            if(this.userProfile && this.userProfile.account){
                this.determineName();
            }
        });
    }

    determineName(){
        if(this.userProfile.name != null){
            var splitted = this.userProfile.name.split(" ", 3); 
            if(splitted[0].length < 3){
                this.userProfile.name = splitted[1] ? splitted[0] + ' ' + splitted[1] : splitted[0];
            } else {
                this.userProfile.name = splitted[0];
            }
        }
    }

    getStats() {
        this.apiService.genericGET(this.authToken, 'analytics/admin-stats').subscribe(res => {
            this.stats = res;
        });
    }

    
}
