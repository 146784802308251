<div>
    <form (ngSubmit)="onSubmit()" [formGroup]="form">

        <div *ngFor="let question of questions" class="form-row" style="margin: 0px;">
            <div class="full-width" [ngSwitch]="question.controlType">
                <app-dynamic-form-question *ngSwitchDefault style="width: 100%;" [question]="question" [form]="form">
                </app-dynamic-form-question>

                <app-dynamic-label-component [question]="question" *ngSwitchCase="'question_label'"></app-dynamic-label-component>

                <app-dynamic-form-dual-select (selectionMade)="onSelectionMade($event)" [question]="question" *ngSwitchCase="'dual-select'"></app-dynamic-form-dual-select>
                <!-- 
                <app-dynamic-form-select-static (selectionMade)="onSelectionMade($event)" [question]="question" *ngSwitchCase="'select'"></app-dynamic-form-select-static> -->

                <app-dynamic-autocomplete-component (selectionMade)="onSelectionMade($event)" [question]="question" *ngSwitchCase="'autocomplete'">

                </app-dynamic-autocomplete-component>

                <app-dynamic-multi-autocomplete-component (selectionMade)="onSelectionMade($event)" [question]="question" *ngSwitchCase="'multi_autocomplete'">

                </app-dynamic-multi-autocomplete-component>

                <app-dynamic-baseform-question-component (selectionMade)="onSelectionMade($event)" [question]="question" *ngSwitchCase="'baseform_question'">

                </app-dynamic-baseform-question-component>
            </div>

        </div>
        <br>
        <div class="form-row">
            <button mat-raised-button type="submit" class="fine-pine" [disabled]="!form.valid">Submit</button>
        </div>
    </form>
</div>