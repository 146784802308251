<div class="menus">
    <div class="toolbar-sec">
        <div class="menu-button" routerLinkActive="active" [routerLink]="'/home'">
            Home
        </div>
        <div class="menu-button" routerLinkActive="active" [routerLink]="'/organizations'">
            Organizations
            <mat-icon (click)="$event.stopPropagation()" [matMenuTriggerFor]="organizations">expand_more</mat-icon>
        </div>
        <div class="menu-button" routerLinkActive="active" [routerLink]="'/users'">
            User management
            <mat-icon (click)="$event.stopPropagation()" [matMenuTriggerFor]="users">expand_more</mat-icon>
        </div>
        <div class="menu-button" routerLinkActive="active" [routerLink]="'/billing'">
            Billing
            <mat-icon (click)="$event.stopPropagation()" [matMenuTriggerFor]="billing">expand_more</mat-icon>
        </div>
        <div class="menu-button" routerLinkActive="active" [routerLink]="'/common'">
            Common
            <mat-icon (click)="$event.stopPropagation()" [matMenuTriggerFor]="common">expand_more</mat-icon>
        </div>

        <mat-menu xPosition="before" #organizations="matMenu">
            <a mat-menu-item routerLink="/organizations/list">Organizations</a>
        </mat-menu>

        <mat-menu xPosition="before" #users="matMenu">
            <a mat-menu-item routerLink="/users/list">Users</a>
            <a mat-menu-item routerLink="/users/roles">Roles</a>
        </mat-menu>

        <mat-menu xPosition="before" #billing="matMenu">
            <a mat-menu-item routerLink="/billing/list">Billing accounts</a>
            <a mat-menu-item routerLink="/billing/app-services">Billing Services</a>
            <a mat-menu-item routerLink="/billing/billing-transactions">Billing transactions</a>
            <a mat-menu-item routerLink="/billing/billing-invoices">Billing invoices</a>
        </mat-menu>

        <mat-menu xPosition="before" #common="matMenu">
            <a mat-menu-item routerLink="/common/reports">Report definitions</a>
            <a mat-menu-item routerLink="/common/dashboard-filters">Dashboard filters</a>
            <a mat-menu-item routerLink="/common/task-logs">Task logs</a>
        </mat-menu>
    </div>
</div>