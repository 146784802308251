import { BaseQuestion } from './base-question';


export class MultiAutoCompleteQuestion extends BaseQuestion<string> {
    controlType = 'multi_autocomplete';
    type: string;
    path: string;
    value: any;
    // secondaryVariables: string[];

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || [];
        this.path = options['path'];
        // this.secondaryVariables = options['secondaryVariables'];
    }
}

