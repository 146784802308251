import { MenuToolbarModule } from './../menu_toolbar/menu_toolbar.module';
import { AccountLogoModule } from './../account-logo/account-logo.module';
import { UserProfileModule } from './../../_modals/user_profile/user_profile.module';
import { MainToolbarComponent } from './main-toolbar.component';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'src/app/_material/my_material.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UserProfileModule,
        AccountLogoModule,
        MyMaterialModule,
        MenuToolbarModule
    ],
    declarations: [
        MainToolbarComponent
    ],
    exports: [
        MainToolbarComponent,
    ]
}) export class MainToolbarModule {

}
