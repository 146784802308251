import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInGuard } from './_guards/logged-in.guard';


const MAIN_ROUTES: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },

  { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard] },
  { path: 'organizations', loadChildren: () => import('./organizations/organizations.module').then(m => m.OrganizationsModule), canActivate: [LoggedInGuard] },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [LoggedInGuard] },
  { path: 'billing', loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule), canActivate: [LoggedInGuard] },
  { path: 'common', loadChildren: () => import('./common/common.module').then(m => m.AppCommonModule), canActivate: [LoggedInGuard] }
];

@NgModule({
  imports: [
    RouterModule.forRoot(MAIN_ROUTES, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
}) export class AppRoutingModule {

}
