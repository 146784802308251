import { AuthtokenService } from './../_services/authtoken.service';
import { AuthService } from './../_services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
    selector: 'app-login-component',
    templateUrl: './login.component.html',
    styleUrls: [
        './login.component.css',
    ]
}) export class LoginComponent implements OnInit {
    title = 'ui-admin';
    loading: boolean;
    newPasswordRequired = false;

    token: any;
    user: any;

    loginForm: UntypedFormGroup;
    pattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');


    constructor(
        private formBuilder: UntypedFormBuilder,
        private snackbar: MatSnackBar,
        private authService: AuthService,
        private tokenService: AuthtokenService,
        private router: Router
    ) {
        this.initForm();
        this.loading = false;
    }

    initForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    ngOnInit() { }

    login() {
        if (this.loginForm.valid) {
            this.loading = true;
            if (this.newPasswordRequired) {
                // todo: future implementation for forgot password
            } else {
                this.normalLogin();
            }
        } else {
            this.snackbar.open('Email and|or password invalid', 'ERROR');
        }
    }

    normalLogin() {
        this.loading = true;
        this.authService.signIn({ username: this.loginForm.get('email').value, password: this.loginForm.get('password').value })
            .subscribe((res: any) => {
                sessionStorage.setItem('accessToken', res.accessToken);
                sessionStorage.setItem('tokenType', res.tokenType);
                this.tokenService.setToken(res.accessToken);
                this.loading = false;
                this.router.navigate(['/home']);
            }, () => {
                this.snackbar.open('error retreiving your login', 'ERROR', { duration: 2000 });
                this.loading = false;
            });
    }
}
