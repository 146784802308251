import { MenuToolbarComponent } from './menu_toolbar.component';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'src/app/_material/my_material.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MyMaterialModule,
    ],
    declarations: [
        MenuToolbarComponent
    ],
    exports: [
        MenuToolbarComponent,
    ]
}) export class MenuToolbarModule {

}
