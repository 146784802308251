import { ApiService } from 'src/app/_services/api.service';
import { AutoCompleteQuestion } from './../../_models/questions/autocomplete-question';
import { AuthtokenService } from 'src/app/_services/authtoken.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';


@Component({
    selector: 'app-dynamic-autocomplete-component',
    templateUrl: './dynamic-autocomplete.component.html',
    styleUrls: [
        './dynamic-autocomplete.component.css'
    ]
}) export class DynamicAutocompleteComponent implements OnInit {
    @Input() question: AutoCompleteQuestion;
    @Output() selectionMade = new EventEmitter<any>();

    myControl = new UntypedFormControl();
    filteredOptions: any[] = [];

    authToken: any;
    loading = false;
    spinnerLoading = false;
    meta: any;

    constructor(
        private tokenService: AuthtokenService,
        private apiService: ApiService
    ) {
        this.loading = false;
        this.tokenService.currentToken.subscribe(token => {
            if (token) {
                this.authToken = token;
            }
        });
    }

    ngOnInit() {
        this.myControl.valueChanges
            .pipe(
                debounceTime(300),
                tap(() => this.spinnerLoading = true),
                switchMap(value => this.apiService.genericGET(
                    this.authToken,
                    this.question.path,
                    { query: value }
                ).pipe(
                    finalize(() => this.spinnerLoading = false),
                ))).subscribe(res => this.filteredOptions = res.content ? res.content : res);

        this.myControl.setValue('');

        if (this.question.value) {
            this.myControl.setValue(this.question.value);
            this.question.secondaryVariables.forEach(element => {
                this.selectionMade.emit({ key: element[1], data: this.question.value[element[0]] });
            });
        }
    }

    displayFn(user?: any): string | undefined {
        return user ? user.name : undefined;
    }

    onItemSelect(event: any) {
        this.question.secondaryVariables.forEach(element => {
            this.selectionMade.emit({ key: element[1], data: event.option.value[element[0]] });
        });
    }
}
