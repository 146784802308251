<div>
    <h5>{{question.label}}</h5>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z2">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element}} </td>
        </ng-container>

        <!-- Create Column -->
        <ng-container matColumnDef="create">
            <th mat-header-cell *matHeaderCellDef> Create </th>

            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="createChanged($event, row)" [checked]="createSelection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>


        <!-- Read Column -->
        <ng-container matColumnDef="read">
            <th mat-header-cell *matHeaderCellDef> Read </th>

            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="readChanged($event, row)" [checked]="readSelection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Update Column -->
        <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef> Update </th>

            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="updateChanged($event, row)" [checked]="updateSelection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> Delete </th>

            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="deleteChanged($event, row)" [checked]="deleteSelection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
    </table>

</div>