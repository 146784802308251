import { AppHeightDirectiveModule } from './../_directives/appHeight/appHeight.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { MyMaterialModule } from '../_material/my_material.module';
import { MainToolbarModule } from '../_components/main-toolbar/main-toolbar.module';

@NgModule({
    imports: [
        CommonModule,
        MyMaterialModule,
        AppHeightDirectiveModule,
        MainToolbarModule,
    ],
    declarations: [
        HomeComponent
    ],
    exports: [
        HomeComponent
    ]
}) export class HomeModule {

}
