import { MatIconModule } from '@angular/material/icon';
import { QuestionService } from '../../_services/question.service';
import { GenericQuestionnaireComponent } from './generic.questionnaire.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '../../_components/dynamic-form/dynamic-form.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatProgressBarModule,
        MatIconModule,
        DynamicFormModule
    ],
    declarations: [
        GenericQuestionnaireComponent
    ],
    providers: [
        QuestionService
    ],
    exports: [
        GenericQuestionnaireComponent
    ]
}) export class GenericQuestionnaireModule { }
