import { DynamicBaseFormQuestionModule } from './../dynamic-baseform-question/dynamic-baseform-question.module';
import { DynamicMultiAutocompleteModule } from './../dynamic-multi-autocomplete/dynamic-autocomplete.module';
import { DynamicFormDualSelectModule } from './../dynamic-form-dual-select/dynamic-form-dual-select.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionControlService } from './question-control.service';
import { DynamicFormComponent } from './dynamic-form.component';
import { NgModule } from '@angular/core';
import { DynamicFormQuestionModule } from '../dynamic-form-question/question.module';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { DynamicFormSelectModule } from '../dynamic-form-select/dynamic-form-select.module';
import { DynamicAutocompleteModule } from '../dynamic-autocomplete/dynamic-autocomplete.module';
import { DynamicLabelModule } from '../dynamic-label/dynamic-label.module';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,

        MatButtonModule,

        CommonModule,

        DynamicFormQuestionModule,
        DynamicFormDualSelectModule,
        DynamicFormSelectModule,
        DynamicAutocompleteModule,
        DynamicMultiAutocompleteModule,
        DynamicBaseFormQuestionModule,
        DynamicLabelModule
    ],
    declarations: [
        DynamicFormComponent
    ],
    providers: [
        QuestionControlService
    ],
    exports: [
        DynamicFormComponent
    ]
})
export class DynamicFormModule {

}
