import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseQuestion } from '../../_models/questions/base-question';


@Component({
    selector: 'app-dynamic-form-question',
    templateUrl: './question.component.html',
    styleUrls: [
        './question.component.css'
    ]
})
export class DynamicFormQuestionComponent {
    @Input() question: BaseQuestion<any>;
    @Input() form: UntypedFormGroup;
    get isValid() {
        return this.form.controls[this.question.key].valid;
    }
}
