import { BaseQuestion } from './base-question';


export class BaseFormQuestion extends BaseQuestion<any> {
    controlType = 'baseform_question';
    section: string;
    sectionIndex: number;
    parameterType: string;

    constructor(options: { } = {}) {
        super(options);
        this.section = options['section'] || '';
        this.sectionIndex = options['sectionIndex'] || '';
        this.parameterType = options['parameterType'] || '';
    }
}
