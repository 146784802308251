import { QuestionLabel } from './../../_models/questions/question.label';
import { Component, OnInit, Input } from '@angular/core';


@Component({
    selector: 'app-dynamic-label-component',
    templateUrl: './dynamic-label.component.html',
    styleUrls: [
        './dynamic-label.component.css'
    ]
}) export class DynamicLabelComponent implements OnInit {
    @Input() question: QuestionLabel;

    constructor() { }

    ngOnInit() { }
}
