import { SelectionModel } from "@angular/cdk/collections";
import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BaseQuestion } from "src/app/_models/questions/base-question";

/**
 * @title Table with selection
 */
@Component({
  selector: "app-dynamic-form-dual-select",
  styleUrls: ["dynamic-form-dual-select.component.css"],
  templateUrl: "dynamic-form-dual-select.component.html",
})
export class DynamicFormDualSelectComponent implements OnInit {
  @Input() question: BaseQuestion<any>;
  @Output() selectionMade = new EventEmitter<any>();

  displayedColumns: string[] = ["name", "create", "read", "update", "delete"];
  dataSource = new MatTableDataSource<String>();
  createSelection = new SelectionModel<String>(true, []);
  readSelection = new SelectionModel<String>(true, []);
  updateSelection = new SelectionModel<String>(true, []);
  deleteSelection = new SelectionModel<String>(true, []);

  constructor() {}

  ngOnInit() {
    this.dataSource = this.question.options;
    if (this.question.value.length > 0) {
      this.question.value.forEach((element) => {
        if (element.name.includes("_CREATE")) {
          this.createSelection.select(
            element.name.substring(0, element.name.length - 7)
          );
        }
        if (element.name.includes("_READ")) {
          this.readSelection.select(
            element.name.substring(0, element.name.length - 5)
          );
        }
        if (element.name.includes("_UPDATE")) {
          this.updateSelection.select(
            element.name.substring(0, element.name.length - 7)
          );
        }
        if (element.name.includes("_DELETE")) {
          this.deleteSelection.select(
            element.name.substring(0, element.name.length - 7)
          );
        }
      });
    }
  }

  createChanged(event: any, row: any) {
    if (event) {
      this.createSelection.toggle(row);
      this.updateParent();
    }
  }

  readChanged(event: any, row: any) {
    if (event) {
      this.readSelection.toggle(row);
      this.updateParent();
    }
  }

  updateChanged(event: any, row: any) {
    if (event) {
      this.updateSelection.toggle(row);
      this.updateParent();
    }
  }

  deleteChanged(event: any, row: any) {
    if (event) {
      this.deleteSelection.toggle(row);
      this.updateParent();
    }
  }

  updateParent() {
    const arr: string[] = [];

    this.createSelection.selected.forEach((element) => {
      arr.push(element + "_CREATE");
    });

    this.readSelection.selected.forEach((element) => {
      arr.push(element + "_READ");
    });

    this.updateSelection.selected.forEach((element) => {
      arr.push(element + "_UPDATE");
    });

    this.deleteSelection.selected.forEach((element) => {
      arr.push(element + "_DELETE");
    });

    this.selectionMade.emit({ data: arr, key: this.question.key });
  }
}
