import { ApiService } from "src/app/_services/api.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class QuestionnaireService {
  path: string;
  custom_meta: any = {};
  constructor(private apiService: ApiService) {}

  postFormData(token: string, data: any, meta: any): Observable<any> {
    switch (meta.type) {
      case "user-role":
        this.path =
          meta.action === "create"
            ? "user-roles/list"
            : "user-roles/" + meta.data.id;
        break;
      case "organization":
        this.path =
          meta.action === "create"
            ? "organizations/list"
            : "organizations/" + meta.data.id;
        break;
      case "billing-account":
        this.path = "billing-accounts/list?organizationId=" + data.organization;
        break;
      case "billing-user":
        this.path =
          meta.action === "create"
            ? "billing-users/list"
            : "billing-users/" + meta.data.id;
        break;
      case "billing-transaction":
        this.path =
          meta.action === "create"
            ? "billing-transactions/list"
            : "billing-transactions/" + meta.data.id;
        break;
      case "billing-invoice":
        this.path =
          meta.action === "create"
            ? "billing-invoices/list"
            : "billing-invoices/" + meta.data.id;
        break;
      case "billing-receipt":
        this.path =
          meta.action === "create"
            ? "billing-receipts/list"
            : "billing-receipts/" + meta.data.id;
        break;
      case "billing-invoice-payment":
        this.path = "billing-invoices/payment/" + meta.data.id;
        break;
      case "app-service":
        this.path =
          meta.action === "create"
            ? "app-services/list"
            : "app-services/" + meta.data.id;
        break;
        case "dashboard-filters":
        this.path =
          meta.action === "create"
            ? "dashboard-filters/list"
            : "dashboard-filters/" + meta.data.id;
        break;
    }

    return this.apiService.genericPOSTOrPUT(
      meta.action === "create" ? "post" : "put",
      token,
      this.path,
      data,
      this.custom_meta
    );
  }
}
