import { ReactiveFormsModule } from '@angular/forms';
import { DynamicBaseFormQuestionComponent } from './dynamic-baseform-question.component';
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'src/app/_material/my_material.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,

        MyMaterialModule,
    ],
    declarations: [
        DynamicBaseFormQuestionComponent,
    ],
    exports: [
        DynamicBaseFormQuestionComponent,
    ],
}) export class DynamicBaseFormQuestionModule {

}
