import { BaseQuestion } from './base-question';


export class AutoCompleteQuestion extends BaseQuestion<string> {
    controlType = 'autocomplete';
    type: string;
    path: string;
    secondaryVariables: string[];

    constructor(options: {} = {}) {
        super(options);
        this.type = options['type'] || [];
        this.path = options['path'];
        this.secondaryVariables = options['secondaryVariables'];
    }
}

