export class AppConstants {
  // public static API_HOST_NAME = 'http://localhost:8080/api';
  public static API_HOST_NAME = "https://actisales.angalabapis.com/api";

  public static refactorObject = (objct: any) => {
    const tempObject: any = [];
    // tslint:disable-next-line:prefer-const
    for (let prop in objct) {
      if (objct.hasOwnProperty(prop)) {
        tempObject.push({ name: prop, value: objct[prop] });
      }
    }
    return tempObject;
  };

  public static monthsOfYear: string[] = [
    'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
  ]
}
