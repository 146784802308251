import { UserProfileComponent } from "./user_profile.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MyMaterialModule } from "src/app/_material/my_material.module";

@NgModule({
    imports: [CommonModule, MyMaterialModule],
    declarations: [UserProfileComponent],
    exports: [UserProfileComponent]
})
export class UserProfileModule {}
