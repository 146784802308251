<div class="full-width">
    <div class="label colorone">
        {{question.label}}
    </div>
    <form [formGroup]="myForm">
        <div class="edit-full-width dual-inputs">
            <mat-form-field class="margin-r20">
                <input type="number" matInput placeholder="Price" formControlName="price">
            </mat-form-field>

            <mat-form-field>
                <input type="number" matInput placeholder="Quantity" formControlName="quantity">
            </mat-form-field>
        </div>

        <mat-checkbox class="edit-full-width" formControlName="available">Available</mat-checkbox>

        <mat-form-field class="edit-full-width">
            <textarea placeholder="Comments" matInput formControlName="comments"></textarea>
        </mat-form-field>
    </form>
</div>