import { BaseFormQuestion } from './../_models/questions/baseform-question';
import { MultiAutoCompleteQuestion } from './../_models/questions/multi-autocomplete-question';
import { AutoCompleteQuestion } from './../_models/questions/autocomplete-question';
import { CheckboxQuestion } from './../_models/questions/checkbox-question';
import { DateQuestion } from './../_models/questions/date.question';
import { CheckboxGroupQuestion } from './../_models/questions/checkbox-group-question';
import { DualSelectQuestion } from './../_models/questions/dual-select.question';
import { TextboxQuestion } from './../_models/questions/textbox-question';
import { SelectQuestion } from '../_models/questions/select.question';
import { NumberboxQuestion } from '../_models/questions/numberbox-question';
import { DropdownQuestion } from '../_models/questions/dropdown-question';
import { TextAreaQuestion } from '../_models/questions/textarea-question';

export class UtilFunctions {
    /**
     * check whether object is blank
     * @param obj object to check
     */
    static isEmpty(obj) {
        // tslint:disable-next-line:prefer-const
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    static patchFormValues(myObj: Array<any>, meta: any) {

        if (meta.data && meta.data.attributeValues && meta.data.attributeValues.length > 0) {
            meta.data.attributeValues.forEach(element => {
                meta.data['attribute' + element.id] = element.name;
            });
        }
        for (let i = 0; i < myObj.length; i++) {
            const item = myObj[i];
            // tslint:disable-next-line:prefer-const
            for (let [key, value] of Object.entries(meta.data)) {
                switch (item.controlType) {
                    case 'date':
                        // let dateString = '1968-11-16T00:00:00'
                        if (value) {
                            const newDate = new Date('' + value);
                            if (item.key === key) {
                                item.value = newDate;
                            }
                        }
                        break;
                    default:
                        if (item.key === key) {
                            item.value = value;
                        }
                        break;
                }
            }
        }
        return myObj;
    }

    static constructQuestionsArray(data: any, meta: any) {
        const result: any[] = [];
        if (data.texts) {
            data.texts.forEach(element => {
                result.push(new TextboxQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                }));
            });
        }
        if (data.textareas) {
            data.textareas.forEach(element => {
                result.push(new TextAreaQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                }));
            });
        }
        if (data.autocomplete) {
            data.autocomplete.forEach(element => {
                result.push(new AutoCompleteQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                    path: element[5],
                    secondaryVariables: element[6]
                }));
            });
        }
        if (data.multi_autocomplete) {
            data.multi_autocomplete.forEach(element => {
                result.push(new MultiAutoCompleteQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                    path: element[5],
                }));
            });
        }
        if (data.numberTexts) {
            data.numberTexts.forEach(element => {
                result.push(new NumberboxQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                }));
            });
        }
        if (data.checkbox) {
            data.checkbox.forEach(element => {
                result.push(new CheckboxQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                    options: meta.options ? meta.options[element[0]] : []
                }));
            });
        }
        if (data.checkboxes) {
            data.checkboxes.forEach(element => {
                result.push(new CheckboxGroupQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    disabled: element[4] ? element[4] : false,
                    options: meta.options[element[0]]
                }));
            });
        }
        if (data.dual_selects) {
            data.dual_selects.forEach(element => {
                result.push(new DualSelectQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    options: meta.options[element[0]]
                }));
            });
        }
        if (data.selects) {
            data.selects.forEach(element => {
                result.push(new SelectQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    options: meta.options[element[0]]
                }));
            });
        }
        if (data.dates) {
            data.dates.forEach(element => {
                result.push(new DateQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    options: meta.options[element[0]]
                }));
            });
        }
        if (data.dropdowns) {
            data.dropdowns.forEach(element => {
                result.push(new DropdownQuestion({
                    key: element[0],
                    label: element[1],
                    value: '',
                    required: element[2],
                    order: element[3],
                    options: meta.options[element[0]]
                }));
            });
        }
        if (meta.attributes) {
            meta.attributes.forEach(element => {
                switch (element.valueType) {
                    case 'TEXT':
                    default:
                        result.push(new TextboxQuestion({
                            key: 'attribute' + element.name,
                            label: element.name,
                            value: '',
                            required: element.mandatory,
                            order: result.length,
                            disabled: false,
                        }));
                        break;
                }
            });
        }
        return result;
    }

    static constructDynamicQuestionsArray(data: any) {
        const result: any[] = [];
        console.log(data);
        // first sort the sections
        data.questions.sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));

        // then add each of the questions
        data.questions.forEach(element => {
            result.push(new BaseFormQuestion({
                key: element.product.id,
                label: element.product.name,
                value: '',
                required: element.required,
                order: element.position,
                disabled: element.disabled,
            }));
        });

        return result;
    }

}
