import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppConstants } from '../_constants/app.constants';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';


@Injectable()
export class ApiService {
    constructor(
        private http: HttpClient,
    ) { }

    genericGET(token: any, path: string, _params?: any): Observable<any> {
        return this.http.get(AppConstants.API_HOST_NAME + '/' + path, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    genericPOSTOrPUT(action: string, token: any, path: string, data: any, _params?: any): Observable<any> {
        if (action === 'post') {
            return this.http.post(AppConstants.API_HOST_NAME + '/' + path,
                JSON.stringify(data), {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }), params: _params ? _params : {}
            });
        } else {
            return this.http.put(AppConstants.API_HOST_NAME + '/' + path,
                JSON.stringify(data), {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }), params: _params ? _params : {}
            });
        }
    }

    genericPOST(token: any, path: string, data: any, _params?: any): Observable<any> {
        return this.http.post(AppConstants.API_HOST_NAME + '/' + path,
                JSON.stringify(data), {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }), params: _params ? _params : {}
            });
    }

    genericPUT(token: any, path: string, data: any, _params?: any): Observable<any> {
        return this.http.put(AppConstants.API_HOST_NAME + '/' + path,
            JSON.stringify(data), {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    genericDELETE(token: any, path: string, _params?: any): Observable<any> {
        return this.http.delete(AppConstants.API_HOST_NAME + '/' + path, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }), params: _params ? _params : {}
        });
    }

    genericUpload(url: string, file: any, contentType: string): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': contentType });
        const req = new HttpRequest(
            'PUT',
            url,
            file,
            {
                headers: headers,
                reportProgress: true
            });
        return this.http.request(req);
    }


    downloadFile(path: string, token: string, _params?: any): Observable<any> {
        return this.http.post(AppConstants.API_HOST_NAME + path, {}, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }), params: _params ? _params : {}, responseType: 'blob' as 'json'
        });
    }
}
