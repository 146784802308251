import { BaseQuestion } from '../../_models/questions/base-question';
import { QuestionControlService } from './question-control.service';
import { Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';


@Component({
    selector: 'app-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: [
        './dynamic-form.component.css'
    ],
    providers: [QuestionControlService],
    encapsulation: ViewEncapsulation.None,
})
export class DynamicFormComponent implements OnInit {

    @Input() questions: BaseQuestion<any>[] = [];
    @Input() origin: string;
    @Output() formValues = new EventEmitter<any>();

    form: UntypedFormGroup;
    payload: any = {    };

    constructor(private qcs: QuestionControlService) {
        this.form = new UntypedFormGroup({});
    }

    ngOnInit() {
        if (this.questions) {
            if (this.origin === 'questionnaire') {
                this.questions.sort((a, b) => (a.order > b.order) ? 1 : -1);
            }
            this.form = this.qcs.toFormGroup(this.questions);
        }
    }

    onSelectionMade(selection: any) {
        this.payload[selection.key] = selection.data;
    }

    onSubmit() {
        for (const key in this.form.value) {
            if (this.form.value.hasOwnProperty(key)) {
                this.payload[key] = this.form.value[key];
            }
        }
        this.formValues.emit(this.payload);
    }

    isEmpty(str) {
        return (!str || 0 === str.length);
    }
}
