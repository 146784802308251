export class ModelFields {
  public static getBillingAccountFields(meta: any): any {
    const resp: any = {
      autocomplete: [
        [
          "organization",
          "Organization",
          true,
          1,
          false,
          "organizations/list",
          [["id", "organization"]],
        ],
      ],
    };

    return resp;
  }

  public static getBillingUserFields(meta: any): any {
    const resp: any = {
      autocomplete: [
        [
          "userAccount",
          "User",
          true,
          1,
          false,
          "user-accounts/list/all",
          [["id", "userAccount"]],
        ],
      ],
      checkbox: [
        ["notifiedForInvoices", "Notified for invoices", false, 2],
        ["notifiedForPayments", "Notified for payments", false, 3],
      ],
    };

    return resp;
  }

  public static getRoleFields(meta: any): any {
    const resp: any = {
      texts: [
        ["name", "Name", true, 0],
        ["description", "Description", true, 1],
      ],
      dropdowns: [
        ["userProfile", "User profile", false, 2],
        ["organization", "Organization", false, 3],
      ],
      dual_selects: [["crudPrivileges", "Privileges", false, 5]],
      checkboxes: [["appPrivileges", "App-role privileges", false, 10]],
    };

    return resp;
  }

  public static getAppServiceFields(meta: any): any {
    const resp: any = {
      texts: [
        ["name", "Name", true, 0],
        ["description", "Description", true, 1],
      ],
      dropdowns: [
        ["billingCycle", "Billing cycle", true, 2],
        ["billingCriteria", "Billing criteria", true, 3],
      ],
      numberTexts: [
        ["unitCost", "Unit cost (KES)", true, 5],
        ["taxRate", "Tax rate (%)", true, 6],
      ],
    };

    return resp;
  }

  public static getBillingInvoiceFields(meta: any): any {
    const resp: any = {
      texts: [["name", "Name", true, 2]],
      dates: [
        ["issueDate", "Issue date", true, 8],
        ["dueDate", "Due date", true, 9],
      ],
    };

    if (meta.action === "create") {
      resp.autocomplete = [];
      resp.autocomplete.push([
        "organizationId",
        "Organization",
        true,
        1,
        false,
        "organizations/list",
        [["id", "organizationId"]],
      ]);

      resp.numberTexts = [];
      resp.numberTexts.push( ["invoiceYear", "Invoice year", true, 4]); 

      resp.dropdowns = [];
      resp.dropdowns.push( ["invoiceMonth", "Invoice month", true, 3]); 
    }

    return resp;
  }

  public static getBillingReceiptFields(meta: any): any {
    const resp: any = {
      autocomplete: [
        [
          "organizationId",
          "Organization",
          true,
          1,
          false,
          "organizations/list",
          [["id", "organizationId"]],
        ],
      ],
      texts: [
        ["name", "Name", true, 2],
        ["comments", "Comments", true, 4],
      ],
      dates: [["issueDate", "Issue date", true, 3]],
    };

    return resp;
  }

  public static getBillingInvoicePaymentFields(meta: any): any {
    const resp: any = {
      numberTexts: [
        ["billingTransactionId", "Transaction ID", true, 1],
        ["amountPaid", "Amount paid", true, 2],
      ],
    };

    return resp;
  }

  public static getBillingTransactionFields(meta: any): any {
    const resp: any = {
      texts: [
        ["paymentReference", "Payment reference", true, 4],
        ["tag", "Tag", false, 6],
        ["comments", "Comments", false, 7],
      ],
      dropdowns: [
        ["paymentMethod", "Payment methods", true, 2],
        ["currency", "Currency", true, 3],
      ],
      numberTexts: [["amount", "Amount", true, 5]],
    };

    if (meta.action === "create") {
      resp.autocomplete = [];
      resp.autocomplete.push([
        "organizationId",
        "Organization",
        true,
        1,
        false,
        "organizations/list",
        [["id", "organizationId"]],
      ]);
    }

    return resp;
  }

  public static getDashboardFilters(meta: any): any {
    const resp: any = {
      texts: [
        ["sid", "ID", true, 1],
        ["entity", "Entity", true, 2],
        ["name", "Name", true, 3],
        ["tableName", "Table name", true, 5]
      ],
      dropdowns: [
        ["columnType", "ColumnType", true, 4],
      ],
    };

    return resp;
  }

  public static getAccountFields(meta: any): any {
    const resp: any = {
      texts: [
        ["prefix", "Prefix", true, 0],
        ["name", "Name", true, 1],
        ["description", "Description", true, 2],
        ["tag", "Tag", true, 3],
        ["registrationNo", "Registration no.", true, 5],
        ["vatNo", "VAT No.", true, 6],
        ["building", "Building", true, 7],
        ["street", "Street", true, 8],
        ["postalBox", "Postal office box", true, 9],
        ["postalCode", "Postal office code", true, 10],
        ["city", "City", true, 11],
        ["latitude", "latitude", false, 12],
        ["longitude", "Longitude", false, 13],
        ["telephone", "Telephone", true, 14],
        ["url", "Url/website", false, 15],
        ["email", "Email", true, 16],
      ],
      checkbox: [["enabled", "Enabled", false, 20]],
    };
    return resp;
  }
}
