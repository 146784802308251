<div class="centered-div" [style.height]="contentHeight">
    <div class="progress-bar-container">
        <mat-progress-bar mode="indeterminate" *ngIf="loading" value="40"></mat-progress-bar>
    </div>
    <div class="header">
        <mat-icon class="mat-icon-header" (click)="navigateBack()" *ngIf="!isModal">arrow_back</mat-icon>
        {{meta?.title}}
    </div>
    <br>
    <div class="content">
        <app-dynamic-form class="edit-full-width" [origin]="'questionnaire'" [questions]="questions" (formValues)="formSubmitted($event)"></app-dynamic-form>
    </div>
</div>