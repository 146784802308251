<label *ngIf="question?.label">{{question.label}}</label>
<div class="select-container">
    <div class="header colorthree">
        <p class="some-header"> Available options</p>
        <p class="some-header"> Selected options</p>
    </div>
    <div class="selects-container">
        <div class="left-select">

            <div class="options-container">
                <div class="option" *ngFor="let option of options" (click)="selectLeft(option)" [ngClass]="{'highlighted': selectedLeftRow?.id==option.id}">{{option.name}}</div>
            </div>
        </div>
        <div class="middle-select">
            <button type="button" mat-flat-button (click)="moveRight()" [ngClass]="{'disabled': selectedRightRow}">Add <mat-icon>arrow_forward</mat-icon></button>
            <button type="button" mat-flat-button (click)="moveLeft()" [ngClass]="{'disabled': selectedLeftRow}"><mat-icon>arrow_back</mat-icon>Remove </button>
        </div>
        <div class="right-select">

            <div class="options-container">
                <div class="option" *ngFor="let option of selection" (click)="selectRight(option)" [ngClass]="{'highlighted': selectedRightRow?.id==option.id}">{{option.name}}</div>
            </div>
        </div>
    </div>
</div>