import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { UtilFunctions } from "../_constants/utils.functions";
import { ModelFields } from "../_constants/model.fields";

@Injectable()
export class QuestionService {
  getRoleQuestions(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getRoleFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getOrganizationQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getAccountFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getBillingTransactionQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getBillingTransactionFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getDashboardFilters(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getDashboardFilters(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getBillingInvoicePaymentQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getBillingInvoicePaymentFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getBillingReceiptQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getBillingReceiptFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getBillingInvoiceQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getBillingInvoiceFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getAppServiceQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getAppServiceFields(meta),
        meta
      )
    );
    return result.asObservable();
  }


  getBillingAccountQns(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getBillingAccountFields(meta),
        meta
      )
    );
    return result.asObservable();
  }

  getBillingUserFields(meta: any): Observable<any> {
    const result = new BehaviorSubject({});
    result.next(
      UtilFunctions.constructQuestionsArray(
        ModelFields.getBillingUserFields(meta),
        meta
      )
    );
    return result.asObservable();
  }
}
