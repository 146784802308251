import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


/**
 * todo: implement refreshToken to renew jwt
 */
@Injectable()
export class AuthtokenService implements OnDestroy {
    private tokenSource;
    private appsSource;

    currentToken: any;
    currentApps: any;

    constructor() {
        this.tokenSource = new BehaviorSubject(sessionStorage.getItem('accessToken'));
        this.currentToken = this.tokenSource.asObservable();

        this.appsSource = new BehaviorSubject(JSON.parse(sessionStorage.getItem('apps')));
        this.currentApps = this.appsSource.asObservable();
    }

    setToken(data: any) {
        this.tokenSource.next(data);
    }

    setApps(data: any) {
        this.appsSource.next(data);
    }

    onTimeOut() {

    }

    ngOnDestroy() {

    }

    changeToken() {

    }

}
