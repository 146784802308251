<div [formGroup]="form" class="edit-full-width">
    <label [attr.for]="question.key" *ngIf="question.controlType != 'checkbox'">{{question.label}}</label>

    <div [ngSwitch]="question.controlType">
        <mat-form-field class="edit-full-width" *ngSwitchCase="'textbox'">
            <input matInput [formControlName]="question.key" [id]="question.key" [type]="question.type">
        </mat-form-field>

        <mat-form-field class="edit-full-width" *ngSwitchCase="'textarea'">
            <textarea matInput [formControlName]="question.key" [id]="question.key" [type]="question.type"></textarea>
        </mat-form-field>

        <div class="checkbox-div" *ngSwitchCase="'checkbox'">
            <mat-checkbox [formControlName]="question.key" [id]="question.key">{{question.label}}</mat-checkbox>
        </div>

        <mat-form-field class="edit-full-width" *ngSwitchCase="'dropdown'">
            <mat-select placeholder="Type" [id]="question.key" [formControlName]="question.key">
                <!-- <mat-option>--</mat-option> -->
                <mat-option *ngFor="let opt of question.options" [value]="opt.value">
                    {{opt.key}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngSwitchCase="'checkboxes'">
            <mat-selection-list [id]="question.key" #checkboxes class="edit-full-width" [formControlName]="question.key">
                <mat-list-option *ngFor="let opt of question.options" [value]="opt.value">
                    {{opt.key}}
                </mat-list-option>
            </mat-selection-list>
        </div>

        <div *ngSwitchCase="'date'">
            <div *ngFor="let column of ['whatever'] ; let i=index ">
                <!-- <input mdInput [mdDatepicker]="i " placeholder="Start {{column.label}} " name="{{column.name}} ">
                <button mdSuffix [mdDatepickerToggle]="i "></button>
                <md-datepicker #i></md-datepicker> -->
                <mat-form-field class="edit-full-width ">
                    <input matInput [matDatepicker]="dp_i" [formControlName]="question.key ">
                    <mat-datepicker-toggle matSuffix [for]="dp_i"></mat-datepicker-toggle>
                    <mat-datepicker color="accent" #dp_i></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div *ngSwitchCase=" 'slide' ">
            <mat-slide-toggle [id]="question.key " class="edit-full-width " [color]=" 'primary' " [checked]="question.value " [formControlName]="question.key ">
                Issue closed
            </mat-slide-toggle>
        </div>

    </div>
    <!-- <div class="errorMessage " *ngIf="!isValid ">{{question.label}} is required</div> -->
</div>