import { ApiService } from 'src/app/_services/api.service';
import { AuthtokenService } from 'src/app/_services/authtoken.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, switchMap, tap, finalize } from 'rxjs/operators';
import { MultiAutoCompleteQuestion } from 'src/app/_models/questions/multi-autocomplete-question';


@Component({
    selector: 'app-dynamic-multi-autocomplete-component',
    templateUrl: './dynamic-autocomplete.component.html',
    styleUrls: [
        './dynamic-autocomplete.component.css'
    ]
}) export class DynamicMultiAutocompleteComponent implements OnInit {
    @Input() question: MultiAutoCompleteQuestion;
    @Output() selectionMade = new EventEmitter<any>();

    myControl = new UntypedFormControl();
    filteredOptions: any[] = [];

    presentSelection: any[] = [];

    authToken: any;
    // data: any = {};
    loading = false;
    spinnerLoading = false;
    meta: any;

    constructor(
        private tokenService: AuthtokenService,
        private apiService: ApiService
    ) {
        this.loading = false;
        this.tokenService.currentToken.subscribe(token => {
            if (token) {
                this.authToken = token;
            }
        });

    }

    ngOnInit() {
        if (this.question.value) {
            this.question.value.forEach(element => {
                this.presentSelection.push([element.id, element.name]);
            });
        }
        this.myControl.valueChanges
            .pipe(
                debounceTime(300),
                tap(() => this.spinnerLoading = true),
                switchMap(value => (value.length > 3) ? this.apiService.genericGET(
                    this.authToken,
                    this.question.path,
                    { query: value }
                ).pipe(
                    finalize(() => this.spinnerLoading = false),
                ) : new Observable())).subscribe(res => this.filteredOptions = res.content);
    }

    displayFn(user?: any): string | undefined {
        return user ? user.name : undefined;
    }

    onItemSelect(event: any) {
        let alreadySelected = false;
        this.presentSelection.forEach(el => {
            if (el[0] === event.option.value['id']) {
                alreadySelected = alreadySelected || true;
            }
        });
        if (!alreadySelected) {
            this.presentSelection.push([event.option.value['id'], event.option.value['name']]);
        }
        this.selectionMade.emit({ key: this.question.key, data: this.presentSelection });
    }
}
